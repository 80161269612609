import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import Seo from "../../../components/seo"
import { BlogPageBody } from "../../../components/blog-page-body"

const BlogPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const tags = data.allMicrocmsTag.edges.map(x => x.node)
  const categories = data.allMicrocmsCategory.edges.map(x => x.node)

  return (
    <Layout title={siteTitle} tags={tags} categories={categories}>
      <BlogPageBody b={data.microcmsBlog}/>
    </Layout>
  )
}

export default BlogPage

export const Head = ({ data }) => (
   <Seo
     title={data.microcmsBlog.title}
     description={data.microcmsBlog.description}
     image_url={data.microcmsBlog.thumbnail?.url}
     type="article"
   />
 )

export const query = graphql`
  query BlogPage($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      blogId
      title
      date
      body
      publishedAt
      thumbnail {
        url
      }
      description
      tags {
        name
      }
      category {
        name
      }          
    }
    site {
      siteMetadata {
        title
      }
    }
    allMicrocmsCategory(limit: 30, skip:0) {
      totalCount
      edges {
        node {
          categoryId
          name
          blogsCount
        }
      }
    }
    allMicrocmsTag(limit: 100, skip:0) {
      totalCount
      edges {
        node {
          tagId
          name
          blogsCount
        }
      }
    }
  }
`